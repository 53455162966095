import { createAction, createReducer } from 'redux-act';

// simple action creators
export const eventDetailLoadSucceeded = createAction<ResolvedEventDetailData>(
  'events/loadDetailSucceeded'
);

export default createReducer<EventEntity<Event>>(
  {
    [eventDetailLoadSucceeded.toString()]: (state: any, payload) => {
      const item = payload.item;
      return {
        ...state,
        evenDetail: item,
      };
    },
  },
  {
    eventDetail: new ListsEvent({
      id: 0,
      title: '',
      title_image: {
        thumb: {
          url: '',
        },
        url: '',
      },
      published_at: '',
      content: '',
      event_at: '',
      can_calendar: false,
      can_iine: false,
    }),
  }
);

// types
import type { EventEntity } from '@/store/index';
import type { ResolvedEventDetailData } from '@/app/events/launchGetEventDetail';
import type Event from '@/domain/events';
import ListsEvent from '@/domain/events';
