import { createAction, createReducer } from 'redux-act';
import { byId, allIds } from '@/infra/normalizer';

// simple action creators
export const postsLoadSucceeded = createAction<ResolvedData>(
  'posts/loadSucceeded'
);

export default createReducer<DomainEntity<Post>>(
  {
    [postsLoadSucceeded.toString()]: (state: any, payload) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...byId(payload),
        },
        allIds: [...state.allIds, ...allIds(payload)],
        lastId: lastId(payload),
      };
    },
  },
  {
    byId: {},
    allIds: [],
    lastId: null,
  }
);

function lastId(entities = []) {
  if (entities.length == 0) return null;

  const lastId = entities.slice(-1)[0]['id'];
  if (lastId - 1 <= 0) return null;

  return lastId - 1;
}

// types
import type { DomainEntity } from '@/store/index';
import type { ResolvedData } from '@/app/posts/userPostsGetById';
import type Post from '@/domain/posts';
