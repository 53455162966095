import { createAction, createReducer } from 'redux-act';
import APP_STATES from '@/store/consts/appStates';

export const appLoadStarted = createAction('app/loadStarted');
export const appLoadFailed = createAction<any>('app/loadFailed');
export const appLoadSucceeded = createAction('app/loadSucceeded');
export const appCtxLoaded = createAction<any>('app/ctxLoaded');
export const appLoggedinSucceeded = createAction<any>('app/loggedinSucceeded');
export const appCountryDetermined = createAction<any>('app/countryDetermined');
export const appLanguageDetermined = createAction<any>(
  'app/languageDetermined'
);
export const appUserAgentDetermined = createAction<any>(
  'app/userAgentDetermined'
);
export const appFetchSucceeded = createAction<string[]>('app/fetchSucceeded');

export const appLoginAction = (body) => async (dispatch) => {
  dispatch(appLoadStarted());

  try {
    const res = await fetch('/api/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
    if (res.status === 200) {
      const data = await res.json();
      dispatch(appLoggedinSucceeded(data));
      dispatch(appLoadSucceeded());
    } else if (res.status === 401) {
      // setErrorMsg('password is wrong or you have not registered yet.');
      dispatch(appLoadFailed(await res.text()));
    } else {
      dispatch(appLoadFailed(await res.text()));
    }
  } catch (error) {
    console.error('An unexpected error happened occurred:', error);
    // setErrorMsg(error.message);
    dispatch(appLoadFailed(error.message));
  }
};

export default createReducer<App>(
  {
    [appCountryDetermined.toString()]: (state, payload) => {
      return {
        ...state,
        country: payload,
      };
    },
    [appLanguageDetermined.toString()]: (state, payload) => {
      return {
        ...state,
        language: payload,
      };
    },
    [appUserAgentDetermined.toString()]: (state, payload) => {
      return {
        ...state,
        userAgent: payload,
      };
    },
    [appLoggedinSucceeded.toString()]: (state, payload) => {
      return {
        ...state,
        loginUser: payload.user,
      };
    },
    [appLoadStarted.toString()]: (state) => {
      return {
        ...state,
        currentStatus: APP_STATES.SUBMITTING,
      };
    },
    [appLoadSucceeded.toString()]: (state) => {
      return {
        ...state,
        currentStatus: APP_STATES.SUCCESS,
      };
    },
    [appLoadFailed.toString()]: (state) => {
      return {
        ...state,
        currentStatus: APP_STATES.INVALID,
      };
    },
    [appCtxLoaded.toString()]: (state, payload) => {
      return {
        ...state,
        ctx: { ...payload },
      };
    },
    [appFetchSucceeded.toString()]: (state, payload: string[]) => {
      return {
        ...state,
        fetchedPaths: [...state.fetchedPaths, ...payload],
      };
    },
  },
  {
    currentStatus: APP_STATES.PRISTINE,
    loginUser: {
      name: '',
      createdAt: '',
      link: '',
    },
    country: '',
    language: '',
    fetchedPaths: [],
  }
);

// types
import type { App } from '@/store/index';
