import type { ResolvedData } from '@/app/items/itemGetById';
import type ItemDetail from '@/domain/item_detail';
import { createAction, createReducer } from 'redux-act';

// action creators
export const itemDetailsLoadSucceeded = createAction<ResolvedData>(
  'itemDetails/loadSucceeded'
);

export default createReducer<State>(
  {
    [itemDetailsLoadSucceeded.toString()]: (
      state: State,
      payload: ItemDetailsLoadSuceededPayload
    ): State => {
      return {
        byId: {
          ...state.byId,
          [payload.item.id]: payload.item,
        },
        allIds: Array.from(new Set([...state.allIds, payload.item.id])),
      };
    },
  },
  {
    byId: {},
    allIds: [],
  }
);

export interface State {
  byId: { [id: string]: ItemDetail };
  allIds: string[];
}

export interface ItemDetailsLoadSuceededPayload {
  item: ItemDetail;
}
