import React, { useEffect } from 'react';

import App, { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Provider } from 'react-redux';
import withRedux from '@/store/with-redux-store';
import '@/styles/reset.css';
import '@/styles/global.css';
import { appWithTranslation } from '@/i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import * as Sentry from '@sentry/node';
import { Integrations } from '@sentry/tracing';
import * as gtag from '@/lib/gtag';

library.add(fas, far);

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Store } from 'redux';

type Props = AppProps & {
  store: Store<any, any>;
};

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  // Sentry.init({
  //   enabled: true,
  //   environment: process.env.NODE_ENV,
  //   dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  //   // @ts-ignore
  //   integrations: [new Integrations.BrowserTracing()],
  //   tracesSampleRate: 1.0,
  // });
}

const MyApp = ({ Component, pageProps, store }: Props) => {
  const router = useRouter();

  useEffect(() => {
    if (!gtag.existsGaId) {
      return;
    }

    const handleRouteChange = (path) => {
      gtag.pageview(path);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <Provider store={store}>
      <Component {...pageProps} />
    </Provider>
  );
};

MyApp.getInitialProps = async (appContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default appWithTranslation(withRedux(MyApp));
