import type Archives from '@/domain/archives';
import type ItemDetail from '@/domain/item_detail';
import type Launch from '@/domain/launch';
import type Event from '@/domain/events';
import type Post from '@/domain/posts';
import type User from '@/domain/users';
import type Search from '@/domain/search';
import APP_STATES from '@/store/consts/appStates';
import reducers from '@/store/reducer';
import type { NextPageContext } from 'next';
import type { CombinedState, Store } from 'redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import Shortcut from '@/domain/shortcuts';

const initialState: RootState = {
  app: {
    currentStatus: APP_STATES.PRISTINE,
    loginUser: {
      name: '',
      createdAt: '',
      link: '',
    },
    country: 'jp',
    language: 'ja',
    fetchedPaths: [],
  },
  ui: {},
  domain: {
    archives: {
      byId: {},
      allIds: [],
      hasNoMoreData: false,
    },
    itemDetails: {
      byId: {},
      allIds: [],
    },
    launch: {
      byId: {},
      allIds: [],
      hasNoMoreData: false,
      isLaunch: true,
    },
    events: {
      byId: {},
      allIds: [],
      listEvent: [],
    },
    archivedEvents: {
      byId: {},
      allIds: [],
      listEvent: [],
    },
    eventDetail: {
      eventDetail: null,
    },
    posts: {
      byId: {},
      allIds: [],
      lastId: null,
    },
    wantItems: {
      byId: {},
      allIds: [],
      lastId: null,
    },
    haveItems: {
      byId: {},
      allIds: [],
      lastId: null,
    },
    userProfile: {
      id: 0,
      name: '',
      introduction: '',
      images: [],
      followCount: 0,
      followerCount: 0,
    },
    post: {
      id: 0,
      body: '',
      userName: '',
      userId: 0,
      userImages: [],
      editedAt: '',
      itemId: 0,
      publishedFlg: false,
      deletedFlg: false,
      images: [],
      iineCount: 0,
    },
    searchItems: {
      byId: {},
      allIds: [],
    },
    search: {
      searchText: '',
    },
  },
};

export function initializeStore(state = initialState): Store {
  const store = createStore(
    reducers,
    state,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );

  // IF REDUCERS WERE CHANGED, RELOAD WITH INITIAL STATE
  if ((module as any).hot) {
    (module as any).hot.accept('@/store/reducer.ts', () => {
      const createNextReducer = require('@/store/reducer.ts').default;

      store.replaceReducer(createNextReducer(state));
    });
  }

  return store;
}

// types
export type LoginUser = {
  name?: string;
  createdAt: string;
  link: string;
};
export interface App {
  currentStatus: string;
  ctx?: NextPageContext;
  loginUser?: LoginUser;
  country: string;
  language: string;
  fetchedPaths: string[];
  userAgent?: string;
}
export type ById<T> = {
  [key: string]: T;
};
export interface DomainEntity<T> {
  byId: ById<T>;
  allIds: string[];
  scrollId?: string | null;
  lastId?: number | null;
  hasNoMoreData?: boolean;
  isLaunch?: boolean;
  listEvent?: Event[] | null;
}

export interface LaunchEntity extends DomainEntity<Launch> {
  shortcuts?: Shortcut[];
  shortCutEventIds?: number[];
  usingShortcuts?: false;
  lotteryIds?: string[];
}

export interface ArchiveEntity extends DomainEntity<Archives> {
  shortcuts?: Shortcut[];
  shortCutEventIds?: number[];
  usingShortcuts?: false;
}

export interface EventEntity<T> {
  eventDetail?: Event | null;
}

export interface SearchEntity<T> {
  searchText?: string;
}

export interface Domain {
  launch: LaunchEntity;
  archives: ArchiveEntity;
  itemDetails: DomainEntity<ItemDetail>;
  events: DomainEntity<Event>;
  archivedEvents: DomainEntity<Event>;
  eventDetail: EventEntity<Event>;
  posts: DomainEntity<Post>;
  wantItems: DomainEntity<Launch>;
  haveItems: DomainEntity<Launch>;
  userProfile: User;
  searchItems: DomainEntity<Archives>;
  post: Post;
  search: SearchEntity<Search>;
}
export interface UI {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export interface RootState {
  app: App;
  ui: UI;
  domain: Domain;
}

export type RootStore = Store<CombinedState<RootState>>;
