import { combineReducers } from 'redux';

import archivesReducer from '@/store/ducks/domain/archives';
import itemDetailsReducer from '@/store/ducks/domain/itemDetails';
import launchReducer from '@/store/ducks/domain/launch';
import eventsReducer from '@/store/ducks/domain/events';
import archivedEventsReducer from '@/store/ducks/domain/archivedEvents';
import eventDetailReducer from '@/store/ducks/domain/eventDetail';
import userProfileReducer from '@/store/ducks/domain/userProfile';
import postReducer from '@/store/ducks/domain/postDetail';
import postsReducer from '@/store/ducks/domain/posts';
import wantItemsReducer from '@/store/ducks/domain/wantItems';
import haveItemsReducer from '@/store/ducks/domain/haveItems';
import searchItemsReducer from '@/store/ducks/domain/searchItems';
import searchingReducer from '@/store/ducks/domain/search';

export { archivesLoadSucceeded } from '@/store/ducks/domain/archives';
export { itemDetailsLoadSucceeded } from '@/store/ducks/domain/itemDetails';
export { launchLoadSucceeded } from '@/store/ducks/domain/launch';
export { userProfileLoadSucceeded } from '@/store/ducks/domain/userProfile';
export { postDetailLoadSucceeded } from '@/store/ducks/domain/postDetail';
export { postsLoadSucceeded } from '@/store/ducks/domain/posts';
export { haveItemsLoadSucceeded } from '@/store/ducks/domain/haveItems';
export { wantItemsLoadSucceeded } from '@/store/ducks/domain/wantItems';
export { searchItemsLoadSucceeded } from '@/store/ducks/domain/searchItems';
export { searching } from '@/store/ducks/domain/search';

export default combineReducers({
  archives: archivesReducer,
  itemDetails: itemDetailsReducer,
  launch: launchReducer,
  events: eventsReducer,
  archivedEvents: archivedEventsReducer,
  eventDetail: eventDetailReducer,
  posts: postsReducer,
  wantItems: wantItemsReducer,
  haveItems: haveItemsReducer,
  userProfile: userProfileReducer,
  searchItems: searchItemsReducer,
  post: postReducer,
  search: searchingReducer,
});
