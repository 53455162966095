import { createAction, createReducer } from 'redux-act';

// simple action creators
export const postDetailLoadSucceeded = createAction<ResolvedData>(
  'postDetail/loadSucceeded'
);

export default createReducer<Post>(
  {
    [postDetailLoadSucceeded.toString()]: (state: Post, payload: Post) => {
      return {
        ...state,
        id: payload.id,
        body: payload.body,
        userName: payload.userName,
        userId: payload.userId,
        userImages: payload.userImages,
        editedAt: payload.editedAt,
        itemId: payload.itemId,
        publishedFlg: payload.publishedFlg,
        deletedFlg: payload.deletedFlg,
        images: payload.images,
        iineCount: payload.iineCount,
      };
    },
  },
  {
    id: 0,
    body: '',
    userName: '',
    userId: 0,
    userImages: [],
    editedAt: '',
    itemId: 0,
    publishedFlg: false,
    deletedFlg: false,
    images: [],
    iineCount: 0,
  }
);

// types
import type { ResolvedData } from '@/app/posts/postGetById';
import type Post from '@/domain/posts/index';
