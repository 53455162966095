import { Component } from 'react';
import { initializeStore } from '@/store/index';

const isServer = typeof window === 'undefined';
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

function getOrCreateStore(initialState?: any) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState);
  }
  return window[__NEXT_REDUX_STORE__];
}

export default function withReduxStore(App) {
  return class AppWithRedux extends Component<Props> {
    static async getInitialProps(appContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const store = getOrCreateStore();

      // Provide the store to getInitialProps of pages
      appContext.ctx.store = store;

      const initialProps = App.getInitialProps
        ? await App.getInitialProps(appContext, store)
        : {};
      const initialReduxState = store.getState();
      return {
        ...initialProps,
        initialReduxState,
      };
    }

    render() {
      const { initialReduxState } = this.props;
      return (
        <App {...this.props} store={getOrCreateStore(initialReduxState)} />
      );
    }
  };
}

interface Props {
  initialReduxState: any;
}
