import { combineReducers } from 'redux';
import domainReducer from '@/store/ducks/domain/index';
import UIReducer from '@/store/ducks/ui/index';
import appReducer from '@/store/ducks/app/index';

const rootReducer = combineReducers({
  app: appReducer,
  ui: UIReducer,
  domain: domainReducer,
});

export default rootReducer;
