import dayjs from 'dayjs';

const noimage = {
  url: '/static/images/lists_no_image.png',
};

export default class ListsEvent {
  id: number;
  title: string;
  title_image: {
    thumb?: {
      url: string;
    };
    url: string;
  };
  published_at: string;
  event_at: string;
  content: string; // html

  coverImage: string;
  releaseAt: string;
  calendarTitle: string;
  calendarStartAt: Date;
  calendarEndAt: Date;
  canIine?: boolean;
  canCalendar?: boolean;

  // itemと共通インターフェース
  noImage = false;
  isEvent = true;

  constructor({
    id,
    title,
    title_image,
    published_at,
    event_at,
    content,
    can_calendar,
    can_iine,
    calendar_start_at,
    calendar_end_at,
  }: EventDetailData) {
    this.id = id;
    this.title = title;
    this.title_image = title_image;
    this.published_at = published_at;
    this.event_at = event_at;
    this.content = content;

    this.coverImage = this.title_image?.url || noimage.url;
    this.releaseAt = this.event_at;
    this.calendarTitle = this.title;
    this.canIine = can_iine;
    this.canCalendar = can_calendar;

    // カレンダー時間
    const calendarTerm = (() => {
      if (calendar_start_at) {
        return {
          startDate: dayjs(calendar_start_at).toDate(),
          endDate: calendar_end_at
            ? dayjs(calendar_end_at).toDate()
            : dayjs(calendar_start_at).add(3, 'hour').toDate(),
        };
      }

      return {
        startDate: dayjs(this.event_at).toDate(),
        endDate: dayjs(this.event_at).add(3, 'hour').toDate(),
      };
    })();

    this.calendarStartAt = calendarTerm.startDate;
    this.calendarEndAt = calendarTerm.endDate;
  }
}

import type { EventDetailData } from '@/infra/items/IItemRepository';
