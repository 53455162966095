import { createAction, createReducer } from 'redux-act';
import {
  defaultState as commonDefautState,
  loadSucceedReducer,
} from '@/store/ducks/domain/mixin/itemsLoader';

// types
import type { ArchiveEntity } from '@/store/index';
import type { ResolvedArchiveData } from '@/app/archives/archivesGetAll';
import Shortcut from '@/domain/shortcuts';

// simple action creators
export const archivesInitialLoadSucceeded = createAction<ResolvedArchiveData>(
  'archives/initialLoadSucceeded'
);
export const archivesLoadSucceeded = createAction<ResolvedArchiveData>(
  'archives/loadSucceeded'
);
export const shortcutsForArchiveLoadSucceeded = createAction<Shortcut[]>(
  'launch/loadShortcutsSucceeded'
);

const defaultState: ArchiveEntity = {
  ...commonDefautState,
  shortcuts: [],
  shortCutEventIds: [],
  usingShortcuts: false,
};

export default createReducer<ArchiveEntity>(
  {
    [archivesInitialLoadSucceeded.toString()]: (state, payload) =>
      loadSucceedReducer<ArchiveEntity>(
        {
          ...state,
          shortCutEventIds: payload?.eventIds,
          usingShortcuts: payload?.usingShortcuts,
          allIds: [],
        },
        payload
      ),
    [archivesLoadSucceeded.toString()]: (state, payload) =>
      loadSucceedReducer<ArchiveEntity>(state, payload),
    [shortcutsForArchiveLoadSucceeded.toString()]: (
      state: ArchiveEntity,
      payload: Shortcut[]
    ) => {
      return {
        ...state,
        shortcuts: payload,
      };
    },
  },
  defaultState
);
