import { createAction, createReducer } from 'redux-act';
import { byId, allIds, lastId } from '@/infra/normalizer';

// simple action creators
export const wantItemsLoadSucceeded = createAction<ResolvedData>(
  'wantItems/loadSucceeded'
);

export default createReducer<DomainEntity<Launch>>(
  {
    [wantItemsLoadSucceeded.toString()]: (state: any, payload) => {
      const items = payload.items;
      return {
        ...state,
        byId: {
          ...state.byId,
          ...byId(items),
        },
        allIds: [...state.allIds, ...allIds(items)],
        lastId: lastId(payload),
      };
    },
  },
  {
    byId: {},
    allIds: [],
    lastId: null,
  }
);

// types
import type { DomainEntity } from '@/store/index';
import type { ResolvedData } from '@/app/launch/launchGetAll';
import type Launch from '@/domain/launch';
