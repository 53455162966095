import { createAction, createReducer } from 'redux-act';

// simple action creators
export const userProfileLoadSucceeded = createAction<ResolvedData>(
  'userProfile/loadSucceeded'
);

export default createReducer<User>(
  {
    [userProfileLoadSucceeded.toString()]: (state: any, payload: User) => {
      return {
        ...state,
        id: payload.id,
        name: payload.name,
        introduction: payload.introduction,
        images: payload.images,
        followCount: payload.followCount,
        followerCount: payload.followerCount,
      };
    },
  },
  {
    id: 0,
    name: '',
    introduction: '',
    images: [],
    followCount: 0,
    followerCount: 0,
  }
);

// types
import type { ResolvedData } from '@/app/users/userGetById';
import type User from '@/domain/users';
